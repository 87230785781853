<template>
  <div class="faq">
    <div class="faq-list">
      <div class="content">
        <div class="faq-item" v-for="(item,index) in list" @click="gotoFAQdetail(item)" :key="index">
          <div class="item">
            <div class="left">
              <div class="dot"></div>
              <div>
                <p>{{item.name}}</p>      
              </div>
            </div>
            <div class="arrow"><img  src="@/assets/icons/arrow-right.png" alt="" /></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {getAboutCategory} from '@/api/question'
export default {
  data() {
    return {
      list:[]
    }
  },
  async mounted() {
    document.title=this.$route.params.title
   await this.getFAQdetail()

  },
  methods:{
    gotoFAQdetail(item){

      this.$router.push(`/about?id=${item.id}&langEnv=${this.$route.query.langEnv}`)
    },
   async getFAQdetail(){
     const res=  await  getAboutCategory({id:this.$route.params.id},this.$route.query.langEnv)
     this.list=res.data
    }
  }
};
</script>

<style lang="scss" scoped>
.faq {
  background-color: #fff;
  .faq-list {
    padding: 16px 10px;
    .content {
      background: #fff;
      border-radius: 12px 12px 12px 12px;
      .faq-item {
        padding: 32px 16px 0px 16px;

        .item {
          display: flex;
          align-items: center;
          border-bottom: 1px solid #f8f8f8;
          padding-bottom: 20px;
          justify-content: space-between;
        }
        .left {
          display: flex;
          align-items: center;
          img{
            width: 48px;
            margin-right: 10px;
          }
        }
        .arrow{
          width: 16px;
        }
      }
    }
  }
  .dot{
    width: 4px;
    height: 4px;
    background: #1A1A1A;
    border-radius: 100px;
    margin-right: 4px;
  }
}
</style>
